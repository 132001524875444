import React from 'react';
import './Services.css';

import { Element } from 'react-scroll';

const Services = () => (
  <Element id="services" offset={100}>
    <div style={{ height: "100%", width: "100%", padding: "20px"}}>
      <section className='service-container'>
        <div className='service-text'>
          <h1>Services</h1>
          <h3>Only the best quality</h3>
          <p>*Price will depend on the condition and complexity.</p>
        </div>
        <div className='service-job'>
          <img
            alt="bicycle"
            src="/icon/bicycle.svg"
            height="150px"
          />
          <h3>Custom Bicycle Paint jobs</h3>
        </div>
        <div className='service-job'>
          <img
            alt="helmet"
            src="/icon/helmet.svg"
            height="150px"
          />
          <h3>Custom Helmet Paint jobs</h3>
        </div>
        <div className='service-job'>
          <img
            alt="shock"
            src="/icon/shock.svg"
            height="150px"
          />
          <h3>Suspension Servicing</h3>
        </div>
        <div className='service-job'>
          <img
            alt="wheel"
            src="/icon/wheel.svg"
            height="150px"
          />
          <h3>Wheel Builds</h3>
        </div>
        <div className='service-job'>
          <img
            alt="wrench"
            src="/icon/wrench.svg"
            height="150px"
          />
          <h3>Bicycle Re-assembly</h3>
        </div>
      </section>
    </div>
  </Element>

)

export default Services
