import React from 'react';

// Components
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Blog from './components/Blog';
import Details from './components/Details';
import Services from './components/Services';
import Contact from './components/Contact';

// Styling
import './globalStyle.css'
import './App.css';

function App() {

  return (
    <div style={{ backgroundImage: `url('/backgrounds/main-bg.jpg')`, height: "100%", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundAttachment: "fixed" }}>
      <Navigation/>
      <br/>
      <br/>
      <Hero/>
      <Services/>
      <Details/>
      <Blog/>
      <Contact/>
    </div>
  );
}

export default App;
