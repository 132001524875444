import React from 'react';
import './Blog.css';

import { Element } from 'react-scroll';

const Blog = () => (
  <Element id="blog" offset={100}>
    <div>
      <section className='blog-container-one'>
        <h1>The Shamrock Paintworks Story</h1>
        <div className='text-width'>
          <p>
            Shamrock Paint Works began in 2018 while I was working as a head mechanic at Sprocket and Jack. I have always wanted to push myself and jump into the deep end, and I felt like I needed to push myself to try something new. What started as painting bikes in the alleyway behind the shop and in my parent's storeroom quickly grew and I fell in love with the craft. Before I knew it I was busy almost every weekend and had purchased my own vinyl cutter and after a very steep learning curve and a ton of mistakes, I started to get the hang of it. Fast forward to 2021, we had set up a simple paint booth at the shop where I was given some amazing opportunities from Killat Gear and Curve I was busier than ever, but I was still operating predominately on weekends and after-hours on the side of managing a busy workshop.  After a year-long break and some very questionable career decisions, 2023 arrived and the time came to pursue the career full time!
          </p>
        </div>
      </section>
      <section className='image-banner'>
          <img
            src="/image-banner/1.jpg"
            alt="banner-1"
            className="banner-img"/>
          <img
            src="/image-banner/2.jpg"
            alt="banner-2"
            className="banner-img"/>
          <img
            src="/image-banner/3.jpg"
            alt="banner-3"
            className="banner-img"/>
          <img
            src="/image-banner/4.jpg"
            alt="banner-4"
            className="banner-img"/>
          <img
            src="/image-banner/5.jpg"
            alt="banner-5"
            className="banner-img"/>
          <img
            src="/image-banner/6.jpg"
            alt="banner-6"
            className="banner-img"/>
      </section>
    </div>
  </Element>

)

export default Blog
