import { Navbar, Nav, Container } from 'react-bootstrap';
import { animateScroll } from 'react-scroll';
import './Navigation.css';

const Navigation = () => {

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 100,
      smooth: true
    });
  }

  return (
    <>
      <Navbar collapseOnSelect fixed='top' expand='sm' bg='dark' variant='dark'>
        <Container>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
          <Navbar.Brand onClick={scrollToTop}>
            <img
              alt="logo"
              src="/logo.svg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Shamrock Paintwork's
          </Navbar.Brand>
            <Nav className="ms-auto">
              <Nav.Link href="#services">Services</Nav.Link>
              <Nav.Link href="#blog">Blog</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation